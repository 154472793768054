
export default {
  props: {
    icon: {
      type: Boolean,
      default: false
    },
    label: {
      type: Boolean,
      default: false
    },
    register: {
      type: Boolean,
      default: false
    }
  },
  vessel: {
    link: {
      cursor: 'pointer'
    }
  }
}
